import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AboutSection from '../components/about/AboutSection';
import Features from '../components/features/Features';
import Layout from '../components/Layout';
import Testimonial from '../components/testimonial/Testimonial';
import TopPage from '../components/TopPage/TopPage';

const AboutPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <TopPage page={t('aboutButton')} />
      <AboutSection pic={''} />
      <Features />
      <Testimonial />
    </Layout>
  );
};

export default AboutPage;
