import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import TopPage from '../components/TopPage/TopPage';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

const ContactPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const handleContactForm = (e) => {
    e.preventDefault();

    if (name && email && subject && message) {
      emailjs.send(
        'service_4ektcm7',
        'template_f4170m8',
        {
          to_name: 'Hichem',
          subject: subject,
          from_name: name,
          message: message,
          from_email: email,
        },
        'bOEkSpP6goYCPBjq8'
      );

      setName('');
      setEmail('');
      setMessage('');
      setSubject('');
      showSuccess({ message: 'message sent' });
    } else {
      showError({ message: 'Enter all the fields' });
    }
  };
  const showError = ({ message }) => {
    setErrMsg(message);
    setTimeout(() => {
      setErrMsg('');
    }, 3000);
  };

  const showSuccess = ({ message }) => {
    setSuccess(message);
    setTimeout(() => {
      setSuccess('');
    }, 3000);
  };
  return (
    <Layout>
      <TopPage page={t('contactUs')} />
      <section className='contact-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='contact-title'>{t('getInTouch')}</h2>
            </div>
            <div className='col-lg-8'>
              <form
                className='form-contact contact_form'
                id='contactForm'
                onSubmit={handleContactForm}
              >
                <div className='row'>
                  <div className='col-12'>
                    <div className='form-group'>
                      <textarea
                        className='form-control w-100'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        id='message'
                        cols={30}
                        rows={9}
                        placeholder={t('Enter Message')}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <input
                        className='form-control valid'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        id='name'
                        type='text'
                        placeholder={t('Enter your name')}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <input
                        className='form-control valid'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id='email'
                        type='email'
                        placeholder={t('Enter your Email')}
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='form-group'>
                      <input
                        className='form-control'
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        id='subject'
                        type='text'
                        placeholder={t('Enter Subject')}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  {errMsg && (
                    <div className='alert alert-danger' role='alert'>
                      {errMsg}
                    </div>
                  )}
                  {success && (
                    <div className='alert alert-success' role='alert'>
                      {success}
                    </div>
                  )}
                </div>
                <div className='form-group mt-3'>
                  <button
                    type='submit'
                    className='button button-contactForm boxed-btn'
                  >
                    {t('send')}
                  </button>
                </div>
              </form>
            </div>
            <div className='col-lg-3 offset-lg-1'>
              <div className='media contact-info'>
                <span className='contact-info__icon'>
                  <i className='ti-home' />
                </span>
                <div className='media-body'>
                  <h3>{t('addressValue')}</h3>
                </div>
              </div>
              <div className='media contact-info'>
                <span className='contact-info__icon'>
                  <i className='ti-tablet' />
                </span>
                <div className='media-body'>
                  <a
                    href='http://wa.me/+21654644339'
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    <h3>{t('phoneNum')}</h3>
                  </a>
                  <p>{t('openingHours')}</p>
                </div>
              </div>
              <div className='media contact-info'>
                <span className='contact-info__icon'>
                  <i className='ti-email' />
                </span>
                <div className='media-body'>
                  <a href='mailto:contact@generalpetrogaz.com'>
                    <h3>contact@generalpetrogaz.com</h3>
                  </a>
                  <p>{t('sendMessage')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
