import React from 'react';
import Footer from './footer/Footer';
import Head from './head/Head';

const Layout = (props) => {
  return (
    <>
      <Head />
      {props.children}
      <Footer />
    </>
  );
};

export default Layout;
