import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { projects } from '../../data/projects';
import ProjectCard from '../projectCard/ProjectCard';
import './projTabs.css';

const ProjectsTabs = () => {
  const { t } = useTranslation();
  return (
    <div className='tabs_projs container my-5 '>
      <Tabs
        defaultActiveKey='all'
        id='fill-tab-example'
        className='my-5 justify-content-start'
      >
        <Tab eventKey='all' title={t('all')}>
          <div className='row'>
            {projects.map((item) => (
              <div key={item.id} className='col-lg-4 col-md-6 my-2'>
                <ProjectCard item={item} />
              </div>
            ))}
          </div>
        </Tab>
        <Tab eventKey='SNDP' title='SNDP'>
          <h3 className='company_name'>{t('sndp')}</h3>
          <div className='row'>
            {projects
              .filter((el) => el.company === 'sndp')
              .map((item) => (
                <div key={item.id} className='col-lg-4 col-md-6 my-2'>
                  <ProjectCard item={item} />
                </div>
              ))}
          </div>
        </Tab>
        <Tab eventKey='profile' title='STIR'>
          <h3 className='company_name'>{t('stir')}</h3>
          <div className='row'>
            {projects
              .filter((el) => el.company === 'stir')
              .map((item) => (
                <div key={item.id} className='col-lg-4 col-md-6 my-2'>
                  <ProjectCard item={item} />
                </div>
              ))}
          </div>
        </Tab>
        <Tab eventKey='longer-tab' title='GCT'>
          <h3 className='company_name '>{t('gct')}</h3>
          <div className='row'>
            {projects
              .filter((el) => el.company.toLowerCase() === 'gct')
              .map((item) => (
                <div key={item.id} className='col-lg-4 col-md-6 my-2'>
                  <ProjectCard item={item} />
                </div>
              ))}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ProjectsTabs;
