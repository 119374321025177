import React from 'react';
import { useTranslation } from 'react-i18next';
import './features.css';
const Features = () => {
  const { t } = useTranslation();
  return (
    <section className='ftco-section ftco-no-pt ftco-no-pb ftco-services-2'>
      <div className='container'>
        <div className='row no-gutters d-flex'>
          <div className='col-lg-4 col-md-4 d-flex align-self-stretch '>
            <div className='media block-6 services d-flex gap-3'>
              <div className='icon justify-content-center align-items-center d-flex'>
                <img
                  className='feat-img'
                  src='/img/feature/worker1.png'
                  alt='0'
                />
              </div>
              <div className='media-body pl-4'>
                <h3 className='heading mb-3'>{t('feature1')}</h3>
                <p>{t('feature1Text')}</p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 d-flex align-self-stretch '>
            <div className='media block-6 services services-2 d-flex gap-3'>
              <div className='icon justify-content-center align-items-center d-flex'>
                <img
                  className='feat-img'
                  src='/img/feature/tools.png'
                  alt='0'
                />
              </div>
              <div className='media-body pl-4'>
                <h3 className='heading mb-3'>{t('feature2')}</h3>
                <p>{t('feature2Text')}</p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-4 d-flex align-self-stretch '>
            <div className='media block-6 services d-flex gap-3'>
              <div className='icon justify-content-center align-items-center d-flex'>
                <img
                  className='feat-img'
                  src='/img/feature/rating.png'
                  alt='0'
                />
              </div>
              <div className='media-body pl-4'>
                <h3 className='heading mb-3'>{t('feature3')}</h3>
                <p>{t('feature3Text')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
