import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillPlayFill } from 'react-icons/bs';

const WhyUs = () => {
  const { t } = useTranslation();
  return (
    <div className='chose_us_area chose_bg_1'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8 col-md-8'>
            <div className='chose_info text-center'>
              <h3>{t('whyUs')}</h3>
              <p className='lasrge_text'>{t('whyUsText')}</p>

              <div className='icon_video d-flex justify-content-center'>
                <a
                  target={'_blank'}
                  className='popup-video '
                  href='https://www.youtube.com/watch?v=PCGeQdZpO6A'
                  rel='noreferrer'
                >
                  <BsFillPlayFill className='caret-right' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
