import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.location.protocol !== 'https:') {
  window.location.href =
    'https://' + window.location.host + window.location.pathname;
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
