import i18n from '../i18n';

export const services = [
  {
    id: 1,
    image: 'img/service/1.png',
    title: i18n.t('service1'),
    description:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta iste omnis sit deleniti qui .',
  },
  {
    id: 2,
    image: 'img/service/welder.jpg',
    title: i18n.t('service2'),
    description:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta iste omnis sit deleniti qui .',
  },
  {
    id: 3,
    image: 'img/service/gpl.jpg',
    title: i18n.t('service3'),
    description:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta iste omnis sit deleniti qui .',
  },
  {
    id: 5,
    image: 'img/service/paint.jpg',
    title: i18n.t('service4'),
    description:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta iste omnis sit deleniti qui .',
  },
  {
    id: 4,
    image: 'img/service/indus.jpg',
    title: i18n.t('service5'),
    description:
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta iste omnis sit deleniti qui .',
  },
];
