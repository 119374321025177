import i18n from '../i18n';
export const projects = [
  {
    id: 1,
    company: 'sndp',
    company_name: i18n.t('sndp'),
    image: '/img/projects/agil3.jpg',
    title: i18n.t('LPG storage maintenance'),
    title_details: i18n.t('proj1Details'),
    duration: `3 ${i18n.t('month')}`,
    description: i18n.t('proj1Description'),
  },
  {
    id: 2,
    company: 'sndp',
    company_name: i18n.t('sndp'),
    image: '/img/projects/agil4.jpg',
    title: i18n.t('LPG storage maintenance'),
    title_details: i18n.t('proj2Details'),
    duration: `40 ${i18n.t('day')}`,
    description: i18n.t('proj2Description'),
  },
  {
    id: 3,
    company: 'sndp',
    company_name: i18n.t('sndp'),
    image: '/img/projects/agil5.jpg',
    title: i18n.t('LPG storage maintenance'),
    title_details: i18n.t('proj3Details'),
    duration: `45 ${i18n.t('day')}`,
    description: i18n.t('proj3Description'),
  },
  {
    id: 4,
    company: 'sndp',
    company_name: i18n.t('sndp'),
    image: '/img/projects/revetement2.jpg',
    title: i18n.t('proj4Title'),
    title_details: i18n.t('proj4Details'),
    duration: `85 ${i18n.t('day')}`,
    description: i18n.t('proj4Description'),
  },
  {
    id: 5,
    company: 'sndp',
    company_name: i18n.t('sndp'),
    image: '/img/projects/agil2.jpg',
    title: i18n.t('proj5Title'),
    title_details:
      'Changement tuyauterie GPL a foyer universitaire MAMOUD EL MESSADI JENDOUBA',
    duration: `25 ${i18n.t('day')}`,
    description: 'Renouvellement installation GPL-PT',
  },
  {
    id: 6,
    company: 'sndp',
    company_name: i18n.t('sndp'),
    image: '/img/projects/stir.jpg',
    title: i18n.t('proj6Title'),
    title_details: 'Branchement Reservoir GPL à HOTEL KELIBIA BEACH-Nabeul',
    duration: `20 ${i18n.t('day')}`,
    description:
      'RAMPE D’ARROSAGE-MISE A LA TERRE-REVETEMENT RESERVOIRS ET TYUAUTEREIE',
  },
  {
    id: 7,
    company: 'sndp',
    company_name: i18n.t('sndp'),
    image: '/img/projects/tuyaux-stir2.jpg',
    title: i18n.t('proj7Title'),
    title_details: 'Renouvellement tuyauterie GPL a PRISON BORJ ERROUMI',
    duration: `7 ${i18n.t('days')}`,
    description:
      'NOUVELLE TYUAUTERIE SCHEDULE 40- CHANGEMENT ACCESSOIRES-VERIFICATION ETACHEITE INSTALLATION',
  },

  {
    id: 8,
    company: 'stir',
    company_name: i18n.t('stir'),
    image: '/img/projects/stir3.jpg',

    title: i18n.t('proj8Title'),
    title_details: 'PREFABRICATION, MONTAGE ET SOUDURE DES TUYAUTERIE',
    duration: `4 ${i18n.t('month')}`,
    description:
      'Prefabriquation et montage de tuyauterie GPL pour 10000 pouce',
  },
  {
    id: 9,
    company: 'gct',
    company_name: i18n.t('gct'),
    image: '/img/projects/gct2.jpg',
    title: i18n.t('proj8Title'),
    title_details: 'construction structure métallique ',
    duration: `2 ${i18n.t('month')}`,
    description:
      "construction d' une structure métallique a l' usine amonitrate & prefabriquation et montage des tuyauteries double enveloppe",
  },
];
