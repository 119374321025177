/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import './head.css';
import { useTranslation } from 'react-i18next';

const Head = () => {
  const [open, setOpen] = useState(false);
  const [stick, setStick] = useState(false);
  // stick navbar part
  const changeNavbarColor = () => {
    if (window.scrollY >= 210) {
      setStick(true);
    } else {
      setStick(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();

  const language = (x, y) => {
    i18n.changeLanguage(x);
    window.location.reload();
  };
  const [openLang, setOpenLang] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');

  return (
    <header>
      <div className='header-area '>
        <div className='header-top_area d-none d-lg-block'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-xl-5 col-md-5 '>
                <div className='header_left'>
                  <p>{t('openingHours')}</p>
                </div>
              </div>
              <div className='col-xl-7 col-md-7'>
                <div className='header_right d-flex justify-content-end align-items-center'>
                  <div className='language'>
                    <div
                      className='lang-selected'
                      onClick={() => setOpenLang(!openLang)}
                    >
                      {lang === 'en' ? (
                        <img className='flag' src='/img/flag/eng.png' alt='' />
                      ) : (
                        <img
                          className='flag'
                          src='/img/flag/saudi.png'
                          alt=''
                        />
                      )}
                    </div>

                    <ul className={`langs ${openLang && 'openLang'}`}>
                      <li
                        onClick={() => {
                          setLang('en');
                          setOpenLang(false);
                          language('en');
                          localStorage.setItem('lang', 'en');
                        }}
                      >
                        <img className='flag' src='/img/flag/eng.png' alt='' />{' '}
                        English
                      </li>
                      <li
                        onClick={() => {
                          setLang('ar');
                          setOpenLang(false);
                          language('ar');
                          localStorage.setItem('lang', 'ar');
                        }}
                      >
                        <img
                          className='flag'
                          src='/img/flag/saudi.png'
                          alt=''
                        />{' '}
                        العربية
                      </li>
                    </ul>
                  </div>
                  <Link to={'/contact'} className='boxed-btn3'>
                    {t('contactUs')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='address_bar d-none d-lg-block'>
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-xl-3 col-lg-3'>
                <div className='logo'>
                  <Link to={'/'}>
                    <img className='logo-image' src='/img/logo3c.png' alt='' />
                  </Link>
                </div>
              </div>
              <div className='col-lg-9'>
                <div className='address_menu d-flex justify-content-end'>
                  <div className='single_address  d-flex'>
                    <div className='icon'>
                      <img src='img/icon/header-address.svg' alt='' />
                    </div>
                    <div className='address_info'>
                      <h3>{t('address')}</h3>
                      <p>{t('addressValue')}</p>
                    </div>
                  </div>
                  <div className='single_address d-flex'>
                    <div className='icon'>
                      <img src='img/icon/headset.svg' alt='' />
                    </div>
                    <div className='address_info'>
                      <h3>{t('callUs')}</h3>
                      <a
                        className='text-white'
                        href='http://wa.me/+21654644339'
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        {t('phoneNum')}
                      </a>
                      {/* <p>{t('phoneNum')}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id='sticky-header'
          className={`main-header-area ${stick && 'sticky'}`}
        >
          <div className='container'>
            <div className='white_bg_bar'>
              <div className='row align-items-center '>
                <div className='col-12 d-lg-none d-flex justify-content-between'>
                  <div className='logo '>
                    <Link to={'/'}>
                      <img
                        className='logo-image-mob'
                        src='/img/logo2c.png'
                        alt=''
                      />
                    </Link>
                  </div>
                  <button
                    onClick={() => setOpen(!open)}
                    className='navbar-toggler'
                    type='button'
                    data-toggle='collapse'
                    data-target='#ftco-nav'
                    aria-controls='ftco-nav'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                  >
                    <span className='text-white'>
                      {' '}
                      <GiHamburgerMenu className='fs-1' />
                    </span>
                  </button>
                </div>
                <div className='col-xl-8 col-lg-8'>
                  <div className='main-menu  d-none d-lg-block'>
                    <nav>
                      <ul id='navigation'>
                        <li>
                          <NavLink
                            to={'/'}
                            className={({ isActive }) =>
                              isActive ? 'active' : ''
                            }
                          >
                            {t('home')}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? 'active' : ''
                            }
                            to={'/about'}
                          >
                            {t('about')}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? 'active' : ''
                            }
                            to={'/services'}
                          >
                            {t('services')}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? 'active' : ''
                            }
                            to={'/projects'}
                          >
                            {t('projects')}
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? 'active' : ''
                            }
                            to={'/contact'}
                          >
                            {t('contact')}
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>

                <div className='col-12'>
                  <div className=' d-lg-none'>
                    <div>
                      <ul className={`mob-list ${open && 'open'}`}>
                        <li>
                          <NavLink
                            to={'/'}
                            className={({ isActive }) =>
                              isActive ? 'active' : ''
                            }
                          >
                            {t('home')}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? 'active' : ''
                            }
                            to={'/about'}
                          >
                            {t('about')}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? 'active' : ''
                            }
                            to={'/services'}
                          >
                            {t('services')}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? 'active' : ''
                            }
                            to={'/projects'}
                          >
                            {t('projects')}
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? 'active' : ''
                            }
                            to={'/contact'}
                          >
                            {t('contact')}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Head;
