import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const TopPage = ({ page }) => {
  const { t } = useTranslation();
  return (
    <div className='bradcam_area bradcam_bg_1'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='bradcam_text text-center'>
              <h3 className='text-capitalize'>{page} </h3>
              <p>
                <Link to={'/'}>{t('home')}</Link> / {page}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPage;
