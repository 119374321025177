/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className='footer'>
      <div className='download_brochure'>
        <div className='container'>
          {/*<div className='bordered_1px'>
            <div className='row'>
              <div className='col-lg-6 col-md-6'>
                <div className='footer_logo'>
                  <Link to={'/'}>
                    <img
                      style={{ width: '40%' }}
                      src='/img/logo2c.png'
                      alt=''
                      className='logo-image'
                    />
                  </Link>
                </div>
              </div>
               <div className='col-lg-6 col-md-6'>
                <div className='download_btn'>
                  <a>
                    <img src='/img/icon/down.svg' alt='' /> Download Brochure
                  </a>
                </div>
              </div> 
            </div>
          </div>*/}
        </div>
      </div>
      <div className='footer_top'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-4 col-md-6 col-lg-4 '>
              <div className='footer_widget'>
                <div className='footer_logo'>
                  <Link to={'/'}>
                    <img src='/img/logo2c.png' alt='' className='logo-image' />
                  </Link>
                </div>
                <p>
                  {t('addressValue')}
                  <br />
                  <a
                    href='http://wa.me/+21654644339'
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    {t('phoneNum')}
                  </a>
                  <br />
                  <a href='mailto:contact@generalpetrogaz.com'>
                    contact@generalpetrogaz.com
                  </a>
                </p>
              </div>
            </div>

            <div className='col-xl-4 col-md-6 col-lg-4 '>
              <div className='footer_widget'>
                <h3 className='footer_title'>{t('usefulLinks')}</h3>
                <ul>
                  <li>
                    <Link to={'/about'}>{t('about')}</Link>
                  </li>{' '}
                  <li>
                    <Link to={'/services'}> {t('services')}</Link>
                  </li>
                  <li>
                    <Link to={'/projects'}>{t('projects')}</Link>
                  </li>
                  <li>
                    <Link to={'/contact'}> {t('contact')}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-xl-4 col-md-6 col-lg-4'>
              <div className='footer_widget'>
                <h3 className='footer_title '>{t('subscribe')}</h3>
                <form action='#' className='newsletter_form'>
                  <input type='text' placeholder={t('placeholderFotter')} />
                  <button type='submit'>{t('subscribe2')}</button>
                </form>
                <div className='socail_links'>
                  <ul>
                    <li>
                      <a href='#'>
                        <FaFacebookF className='ti-facebook' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter className='ti-twitter-alt' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram className='fa fa-instagram' />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.linkedin.com/in/general-petro-gaz-8128b7233/'
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        <FaLinkedin className='fa ' />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copy-right_text'>
        <div className='container'>
          <div className='footer_border' />
          <div className='row'>
            <div className='col-xl-12'>
              <p className='copy_right text-center'>
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                Copyright © All rights reserved
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
