/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './socialMedia.css';

const SocialMedia = ({ color }) => {
  return (
    // <div className='social'>
    //   <FaWhatsapp className='icon' />
    //   <h4>WhatsApp</h4>
    // </div>
    <ul id='social-sidebar'>
      {/* <li>
        <a className='entypo-twitter'>
          <span>Twitter</span>
        </a>
      </li> */}
      <li>
        <a className='entypo-facebook'>
          <span>Facebook</span>
        </a>
      </li>
      <li>
        <a
          href='http://wa.me/+21654644339'
          target={'_blank'}
          rel='noreferrer'
          className='entypo-whatsapp'
        >
          <FaWhatsapp />
          <span>WhatsApp</span>
        </a>
      </li>
      <li>
        <a
          href='https://www.linkedin.com/in/general-petro-gaz-8128b7233/'
          target={'_blank'}
          rel='noreferrer'
          className='entypo-linkedin'
        >
          <span>LinkedIn</span>
        </a>
      </li>
    </ul>
  );
};

export default SocialMedia;
