import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout';
import ProjectsTabs from '../components/projectsTabs/ProjectsTabs';
import TopPage from '../components/TopPage/TopPage';

const ProjectsPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <TopPage page={t('ourProjects')} />
      <ProjectsTabs />
    </Layout>
  );
};

export default ProjectsPage;
