import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import OurServices from '../components/ourServices/OurServices';
import TopPage from '../components/TopPage/TopPage';
import WhyUs from '../components/whyUs/WhyUs';

const ServicesPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <TopPage page={t('ourServices')} />
      <OurServices />
      <WhyUs />
    </Layout>
  );
};

export default ServicesPage;
