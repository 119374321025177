import React from 'react';
import { Link } from 'react-router-dom';
import { services } from '../../data/services';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const OurServices = ({ title }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className='service_area'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='section_title text-center mb-50'>
              <h3> {title && `${t('ourServices')}`} </h3>
            </div>
          </div>
        </div>
        <div className='border_bottom_1px position-relative'>
          <div className='pattern_img d-none d-xl-block'>
            <img src='img/pattern/pattern.png' alt='' />
          </div>
          <div className='row'>
            {services.map((service) => (
              <div
                key={service.id}
                className='single_service  col-lg-4 col-md-6 col-sm-12'
              >
                <div className='thumb'>
                  <img src={service.image} alt='' />
                </div>
                <div className='service_info'>
                  <Link to={'/projects'}>
                    <h3> {service.title} </h3>
                  </Link>
                  {/* <p>{service.description}</p> */}
                  <Link to={'/projects'} className='d-flex align-items-center'>
                    {t('seeMore')}{' '}
                    {i18n.dir() === 'ltr' ? (
                      <AiOutlineRight className='mt-1 ' />
                    ) : (
                      <AiOutlineLeft className='mt-2 ' />
                    )}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
