import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './homeSlides.css';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HomeSliders = () => {
  const { t } = useTranslation();
  return (
    <div className='slider_area'>
      <div className=' owl-carousel'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          fadeEffect={{ crossFade: true }}
          navigation={false}
          pagination={{
            clickable: true,
          }}
          loop={true}
          speed={1000}
          autoplay={{
            delay: 5500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, EffectFade, Navigation, Pagination]}
          className='mySwiper slider_active'
        >
          <SwiperSlide
            className='single_slider  d-flex align-items-center slider_bg_1 overlay'
            style={{
              opacity: '0 !important',
              backgroundImage: 'url(/img/banner/banner5.jpg)',
            }}
          >
            <div className='container'>
              <div className='row align-items-center justify-content-center'>
                <div className='col-xl-9 col-md-9 col-md-12'>
                  <div className='slider_text text-center'>
                    <h3 className='mb-5'>{t('slide1')}</h3>

                    <Link to={'/services'} className='boxed-btn3'>
                      {t('ourServices')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className='single_slider d-flex align-items-center slider_bg_1 overlay'
            style={{
              opacity: '0 !important',
              backgroundImage: 'url(/img/banner/banner4.jpg)',
            }}
          >
            <div className='container'>
              <div className='row align-items-center justify-content-center'>
                <div className='col-xl-9 col-md-9 col-md-12'>
                  <div className='slider_text text-center'>
                    <h3 className='mb-5'>{t('slide2')}</h3>

                    <Link to={'/services'} className='boxed-btn3'>
                      {t('ourServices')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className='single_slider  d-flex align-items-center slider_bg_1 overlay'
            style={{
              opacity: '0 !important',
              backgroundImage: 'url(/img/banner/banner3.jpg)',
            }}
          >
            <div className='container'>
              <div className='row align-items-center justify-content-center'>
                <div className='col-xl-9 col-md-9 col-md-12'>
                  <div className='slider_text text-center'>
                    <h3 className='mb-5'>{t('slide3')}</h3>

                    <Link to={'/services'} className='boxed-btn3'>
                      {t('ourServices')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default HomeSliders;
