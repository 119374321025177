import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './projectCard.css';

const ProjectCard = ({ item }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className='singl_project'>
      <div className='proj_image'>
        <img src={item.image} alt='cc' />
      </div>
      <div className='proj_title'>
        <div className={`${i18n.dir() === 'ltr' ? 'plus' : 'min'}`}>
          {item.duration}
        </div>

        <h4 onClick={() => navigate(`/projects/${item.id}`, { state: item })}>
          {item.title}
        </h4>
      </div>
    </div>
  );
};

export default ProjectCard;
