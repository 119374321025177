import React, { useEffect } from 'react';
import AboutSection from '../components/about/AboutSection';
import ContactUs from '../components/contactUs/ContactUs';
import Features from '../components/features/Features';
import HomeSliders from '../components/homeSlides/HomeSlides';
import Layout from '../components/Layout';
import OurServices from '../components/ourServices/OurServices';
import Testimonial from '../components/testimonial/Testimonial';
import WhyUs from '../components/whyUs/WhyUs';

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <HomeSliders />
      <Features />
      <AboutSection pic={'2'} />
      <OurServices title={true} />
      <WhyUs />
      <Testimonial />
      <ContactUs />
    </Layout>
  );
};

export default HomePage;
