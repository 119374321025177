import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useTranslation } from 'react-i18next';

const Testimonial = () => {
  const { t } = useTranslation();
  return (
    <div className='testimonial_area '>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='section_title text-center mb-55'>
              <h3>{t('testimonials')}</h3>
            </div>
          </div>
        </div>
        <div>
          {' '}
          <Swiper className='mySwiper slider_active'>
            <SwiperSlide className='single_carousel'>
              <div className='single_testmonial text-center'>
                <div className='testmonial_author'>
                  <div className='thumb'>
                    <img src='img/testimonial/agil.png' alt='' />
                  </div>
                  {/* <h4>SNDP Company</h4> */}
                </div>
                <p>“{t('agil_testimonial')}”</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className='single_carousel'>
              <div className='single_testmonial text-center'>
                <div className='testmonial_author'>
                  <div className='thumb'>
                    <img src='img/testimonial/stir.png' alt='' />
                  </div>
                  {/* <h4>STIR</h4> */}
                </div>
                <p>“{t('stir_testimonial')}”</p>
              </div>
            </SwiperSlide>{' '}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
