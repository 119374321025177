import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <div className='contact_us overlay'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-8 col-md-7'>
            <div className='contact_text'>
              <h3>{t('contactBanner')}</h3>
            </div>
          </div>
          <div className='col-lg-4 col-md-5'>
            <div className='contact_btn'>
              <Link to={'/contact'} className='boxed-btn3'>
                {t('contactUs')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
