import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SocialMedia from './components/socialMedia/SocialMedia';
import './css/style.css';
import './i18n';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import ProjectsPage from './pages/ProjectsPage';
import ServicesPage from './pages/ServicesPage';
import SingleProject from './pages/SingleProject';

// htacces
// SetEnv PHP_VER 5_3
// SetEnv REGISTER_GLOBALS 0

function App() {
  return (
    <BrowserRouter>
      <SocialMedia />
      <BsFillArrowUpCircleFill
        onClick={() => window.scrollTo(0, 0)}
        style={{
          width: '50px',
          height: '50px',
          color: '#fc5e28',
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          borderRadius: '50%',
          backgroundColor: 'white',
          zIndex: '99',
          cursor: 'pointer',
        }}
      />

      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/services' element={<ServicesPage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/projects' element={<ProjectsPage />} />
        <Route path='/projects/:id' element={<SingleProject />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
