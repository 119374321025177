import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AboutSection = ({ pic }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className='about_area'>
      <div className='container'>
        <div className='border_1px'>
          <div className='row align-items-center' style={{ direction: 'ltr' }}>
            <div className='col-xl-6  col-md-6'>
              <div className='about_thumb'>
                <img src={`img/about/about${pic}.png`} alt='' />
                <div className='pattern_img d-none d-lg-block'>
                  <img src='img/pattern/pattern.svg' alt='' />
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-md-6'>
              <div
                className={`about_info ${i18n.dir() === 'rtl' && 'dir-rtl'} `}
              >
                <h3>{t('aboutHead')}</h3>
                <p className='first_para'>{t('aboutPar1')}</p>
                <p>{t('aboutPar2')}</p>
                <p></p>
                <Link to={'/about'} className='boxed-btn3'>
                  {t('aboutButton')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
