import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebookF, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import ContactUs from '../components/contactUs/ContactUs';
import Layout from '../components/Layout';

const SingleProject = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    title,
    title_details,
    company_name,
    image,
    company,
    duration,
    description,
  } = location.state;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          height: '500px',
          backgroundSize: 'cover',
          backgroundPosition: ' center center',
          filter: 'brightness(70%)',
        }}
      ></div>
      <div className='project_details_info'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-10 col-lg-10'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='details_header details_left'>
                    <h4>{title_details || title}</h4>
                  </div>
                  <div className='single_date d-flex gap-2'>
                    <span>{t('client')}:</span>
                    <p>{company_name || company}</p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='details_header details_right'>
                    <div className='date_line d-flex align-items-center justify-content-end'>
                      <div className='single_date'>
                        <span>{t('duration')}</span>
                        <p>{duration}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6 col-md-6'>
                  <div className='details_left'>
                    <p>{description}</p>
                  </div>
                </div>
              </div>
              <div className='border_1px'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='links'>
                      <ul>
                        <li>
                          <a href='#' className='dir-ltr'>
                            <FaFacebookF className='ti-facebook' />
                            Facebook{' '}
                          </a>
                        </li>
                        <li>
                          <a href='#' className='dir-ltr'>
                            {' '}
                            <FaTwitter className='ti-twitter-alt' />
                            Twitter{' '}
                          </a>
                        </li>
                        <li>
                          <a
                            className='dir-ltr'
                            href='https://www.linkedin.com/in/general-petro-gaz-8128b7233/'
                            target={'_blank'}
                            rel='noreferrer'
                          >
                            {' '}
                            <FaLinkedin className='fa fa-instagram' />
                            LinkkenIn
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactUs />
    </Layout>
  );
};

export default SingleProject;
